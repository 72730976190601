@import "./styling/styles.scss";
@import "./styling/common.scss";
@import "./styling/mediaqueries";
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('./assets/fonts/roboto-regular-webfont.woff2') format('woff2'), 
        url('./assets/fonts/roboto-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('./assets/fonts/roboto-light-webfont.woff2') format('woff2'), 
        url('./assets/fonts/roboto-light-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: url('./assets/fonts/roboto-black-webfont.woff2') format('woff2'), 
        url('./assets/fonts/roboto-black-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 7001;
  src: url('./assets/fonts/roboto-bolditalic-webfont.woff2') format('woff2'), 
        url('./assets/fonts/roboto-bolditalic-webfont.woff') format('woff');
}


@font-face {
  font-family: 'JosefinSans';
  font-weight: 700;
  src: url('./assets/fonts/JosefinSans-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'JosefinSans';
  font-weight: 600;
  src: url('./assets/fonts/JosefinSans-SemiBold.ttf') format('ttf');
}
@font-face {
  font-family: 'JosefinSans';
  font-weight: 500;
  src: url('./assets/fonts/JosefinSans-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'JosefinSans';
  font-weight: 400;
  src: url('./assets/fonts/JosefinSans-Regular.ttf') format('ttf');
}

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&family=Montserrat:wght@100&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  // custom styles
  background-color: $pg-bg-clr;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


// General styles
h1 {
  font-size: 4vmin;
}

h1, h2, h3, h4 {
  font-family: 'EB Garamond', 'Montserrat', sans-serif, serif;
}


img {
  width: 100%;
}

.icon {
  cursor: pointer;
}
.comp-name {
  color: $prm-clr;
}
// .menu-area {
//   a {
//     font-family:  sans-serif, serif;
//     font-size: 1rem;
//     font-weight: 100;
//     letter-spacing: 0.8px;
//     &.active {
//       @include activeLink($bgClr: $logo-red-clr);
//     }
//   }
// }




@mixin cover-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

$p: 2.5rem;
// @mixin hover {
//   background: darken(#ddd, 5);
//   opacity: 0.8;
// }
@mixin hover {
  background: $prm-clr;
  color: rgba(255, 255, 255, 0.9);// $ter-clr;// white;
  // opacity: 0.8;
}


.mobile-menu-wrapper {
  @include cover-bg;
  z-index: 990;
  .backdrop {
      @include cover-bg;
      z-index: 998;
      background-color: rgba(0,0,0,0.01);
  }
  .content {
      position: relative;
      right: -100%;
      top: 0;
      transform: translateX(-100%);
      height: 100vh;
      width: 70%;
      max-width: 500px;
      background: white;
      box-shadow: 0 0 10px rgba(0,0,0,0.8);
      z-index: 999;
      display: flex;
      flex-direction: column;
      .header {
            position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem 0;
          padding-left: $p;
          padding-right: $p;
          height: 50px;
      }
      .menus {
          flex-grow: 1;
          font-weight: 500;
          letter-spacing: 0.08rem;
          > .menu {
              padding: 0.25rem 0;
              > *.mobile-menu-link {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0.3rem $p;
                  cursor: pointer;
                  
                  opacity: 0.6;
                  &:hover {
                      @include hover();
                  }
              }
              > *:not(.mobile-menu-link) {
                  .mobile-menu-link {
                      padding: 0.3rem $p;
                      display: block;
                      cursor: pointer;
                      opacity: 0.6;
                      flex-grow: 1;
                  }
                  &:hover {
                      @include hover();
                      .mobile-menu-link {
                          opacity: 1;
                          .icon {
                              opacity: 1;
                          }
                      }
                  }
              }
          }
          .logout {
              margin-top: 10%;
              opacity: 0.6;
              span {
                  padding: 0.3rem $p;
                  letter-spacing: 2px;
                  cursor: pointer;
                  &:hover {
                      @include hover();
                      opacity: 1;
                  }
              }
          }
      }
  }

  &.show {
      display: block;
      // @include slideLeft($duration: 0.3s);
  }
}

