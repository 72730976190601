@import "../../../styling/vars";
@import "../../../styling/mediaqueries";

.quick-links {
    $lk-clr: #878585;
    $lk-at-clr: black;// darken($color: $lk-clr, $amount: 50);
    position: relative;
    a {
        color: $lk-clr;
        position: relative;
        text-decoration: none;
        &.active {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                // left: -50%;
                // transform: translateX(50px);
            }
            span {
                position: relative;
                z-index: 1;
            }
        }
    }
    .footer-nav-links {
        h1 {
            text-align: center;
            font-size: 2rem;
            padding-bottom: 1rem;
            color: $prm-clr;
        }
        // .links {
        //     display: flex;
        //     flex-wrap: wrap;

        //     > div {
        //         width: 50%;
        //         height: 40px;
        //         display: flex;
        //         align-items: center;
        //         > a {
        //             width: 80%;
        //             padding: 5px 0;
        //             @include transition;
        //             color: darken($lk-clr, 0);
        //             &:hover, &.active {
        //                 color: $lk-at-clr;
        //                 // text-decoration: underline;
        //             }
        //             // &.active {
        //             //     color: $lk-at-clr;
        //             // }
        //         }
        //     }
        // }
            
        .links {
            display: flex;
            gap: 20px;
            > div {
                // background: red;
                padding: 5px 0;
                > a {
                    padding: 5px 0;
                    color: darken($lk-clr, 0);
                    // @include transition;
                    transition: all 0.1s linear;
                    &:hover, &.active {
                        color: $lk-at-clr;
                        // font-weight: 500;
                        letter-spacing: 0.05rem;
                    }
                }
            }

            @include maxBreakpoint($bp: 645px) {
                justify-content: center;
            }
        }

        // .qrcode-section {
        //     text-align: center;
        //     h2 {
        //         color: red;
        //         text-align: center;    
        //         margin: 15px 0;
        //     }
        //     > div {
        //         max-width: 15rem;
        //         position: relative;
        //         left: 50%;
        //         transform: translateX(-50%);
        //     }
        // }
        
        // .f-n-lks {
        //     display: flex;
        //     justify-content: space-between;
        //     @include maxBreakpoint($bp: 645px) {
        //         flex-direction: column;
        //         text-align: center;
        //     }
        // }

    }
}


