.logo-area {
    max-width: 190px;
    max-height: 90px;
    height: 90px;
    // background: red;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
        background-color: transparent !important;
        img {
            position: relative;
            // left: -22px;
            width: 189px;
            // mix-blend-mode: difference;
        }
    }
}
