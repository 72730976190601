@import "../../../styling/styles.scss";


.banner {
    position: relative;
    width: 100%;
    height: 96vh;
    @include container-padding;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        @include theme-mask();
        // background: linear-gradient(45deg, black, rgba(0, 0, 0, 0.5), transparent, transparent);
        // background: linear-gradient(30deg, black, rgba(0, 0, 0, 0.5), transparent, transparent);
        // background: linear-gradient(45deg, $prm-clr, rgba(70, 51, 104, 0.764), transparent, transparent);
        // background: linear-gradient(20deg, $prm-clr, rgba(70, 51, 104, 0.5), transparent, transparent);
        
        top:0;
        left: 0;
        right: 0; 
        bottom: 0;
        z-index: 1;
    }



    img {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // .content {
    //     z-index: 90;
    //     color: white;
    //     background: #00000017;
    //     backdrop-filter: blur(10px);
    //     padding: 2rem;
    //     // color: rgb(150, 140, 140);

    //     > h1 {
    //         text-transform: uppercase;
    //         @media only screen and (max-width: 767px) {
    //             font-size: 1.1rem;
    //         }
    //     }
    //     .h1-main {
    //         white-space: nowrap;
    //         font-size: 1.6rem;
    //         margin: 0.4rem 0;
    //         @media only screen and (min-width: 767px) {
    //             font-size: 2.8rem;
    //         }
    //         @media only screen and (max-width: 767px) {
    //             font-size: 1.3rem;
    //         }
    //         @media only screen and (max-width: 567px) {
    //             white-space:normal;
    //         }
    //     }

    //     .nav-btn {
    //         text-decoration: none;
    //         background-color: $primary-accent-clr;
    //         padding: 1rem;
    //         text-transform: uppercase;
    //         display: inline-block;
    //         margin-top: 0.4rem;
    //         color: white;
    //         font-weight: bold;
    //         max-width: 130px;
    //         @media only screen and (max-width: 567px) {
    //             font-size: .8rem;
    //             margin-top: 0.2rem;
    //         }
    //     }

    //     @media only screen and (min-width: 567px) {
    //         width: 100%;
    //         max-width: 1200px;
    //         margin: 0 auto;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: flex-start;
    //     }
    // }

    .content {
        z-index: 1;
        color: white;
        position: absolute;
        bottom: 4rem;
        max-width: $max-width;
        margin: 0 auto;
        padding: 0 5%;
       h1 {
            font-size: 64px;
            letter-spacing: -.64px;
       }
       $margin: 1.5vmin;

       h1.hero-title {
        .hero-title_word {
            display: inline-block;
            margin: 0vmin $margin;
            position: relative;
            transform: translateY(40%);
            transition: none;
            opacity: 0;
            animation: showText 0.5s forwards var(--delay); //cubic-bezier(0.90, 0.06, 0.15, .90);
        }
       }

       .hero-subtitle {
            margin-left: $margin;
            margin-top: $margin + 3.5vmin;
            line-height: 1.6;
            position: relative;
            transform: translateY(40%);
            transition: none;
            opacity: 0;
            animation: showText 0.5s forwards 1000ms; //cubic-bezier(0.90, 0.06, 0.15, .90);
       }

    }
}


@keyframes showText {
    0% {

    }
    100% {
        opacity: 1;
        transform: translateY(0%);
        transition: opacity 0ms, transform 200ms cubic-bezier(0.90, 0.06, 0.15, .90);
    }
}