@import "../../../styling/mediaqueries";
@import "../../../styling/vars";

.social-media-links {
    $clr: rgba(255, 255, 255, 0.467);//#1d96e8;
    $rClr: darken($prm-clr, 10);// darken($clr, 10);
    // background: lighten($color: $primary-clr, $amount: 70);
    background: lighten($color: $prm-clr, $amount: 30);
    color: black;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    perspective: 1000px;

    > div.content {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin-right: 20px;
        }

        @include maxBreakpoint($bp: 645px) {
            flex-direction: column;
            > p {
                margin: 0;
                margin-bottom: 1rem;
            }
        }
    }

    .sm-icon {
        margin: 0 5px;
        border-radius: 50%;
        border: 2px solid $rClr;
        color: $rClr;
        box-sizing: border-box;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 45px;
        text-align: center;
        background: transparent;
        @include transition;
        transform-style: preserve-3d;
        svg {
            font-size: 18px;
        }
        &:hover {
            border: 2px solid $clr;
            color: $clr;
            background: $prm-clr;
        }
    }
}
