@import "../../../styling/vars.scss";
@import "../../../styling/mediaqueries.scss";


.img-bg-container {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: 'center';
    background-repeat: no-repeat;
    background-size: cover;

    .sm-scrn-img {
        display: none;
    }

    @include maxBreakpoint($bp: 1000px) {
        background-image: none !important;
        height: auto;
        margin-top: 90px;// that is the size of the header
        .sm-scrn-img {
            display: block;
            position: relative;
            z-index: -1;
        }
        &::before {
            background: linear-gradient(30deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), transparent, transparent) !important;
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(30deg, black, rgba(0, 0, 0, 0.5), transparent, transparent);
        // @include bg-mask($dir: 0deg);
        // background: linear-gradient(0deg,#f5f5f5 10%, #f5f5f558, transparent, transparent, transparent, transparent);
        // background: linear-gradient(0deg, $prm-clr, transparent);
        // background: linear-gradient(0deg, $prm-clr 2%, transparent, transparent, transparent, transparent);
    }
    img {
        // display: none;
        display: flex;
        position: absolute;
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .content {
        color: white;
        backdrop-filter: blur(10px);
        z-index: 90;
    }
}