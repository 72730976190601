@import "../../../styling/vars";


.service-details {
    .bg-container {
        background-position: 0% 0%;
        background-size: 100% 100%;
        transition: all 350ms ease;

        &::after {
            content: '';
            background: radial-gradient(
                rgba(255,255,255, 0.2) 8%,
                transparent 8%
            );
            background-position: 0% 0%;
            background-size: 5vmin 5vmin;
            width: 100%;
            height: 100%;
            transition: background-position 350ms ease;
        }
        &:hover {
            &::after {
                background-position: -1% 0%;
            }


            background-position: 10% 0%;
            background-size: 110% 110%;
                
        }
    }
    > .content-container {
        .back-link-wrapper {
            text-align: right;
        }

        .summary {
            margin-bottom: 20px;
            line-height: 1.5;
        }
        .bread-crumps {
            margin-bottom: 2.5rem;
            a {
                margin-right: 18px;
                padding-right: 5px;
                position: relative;
                white-space: nowrap;

                &::after {
                    @include rightBF();
                    left: 100%;
                    right: 0;
                    font-size: 15px;
                    top: 55%;
                    transform: translateY(-50%);


                    // width: 0 !important;
                    height: 0 !important;
                    display: flex;
                    align-items: center;
                }
            }
        }

        ul > li {
            list-style: none;
            right: -12px;
            display: flex;
            align-items: center;
            @include rightChevIcon();
            a {
                left: 15px;
                &::after {
                    bottom: 2px;
                }
            }

            line-height: 2;
        }
    }

// transition: opacity 0ms, transform 200ms cubic-bezier(0.90, 0.06, 0.15, 0.90);
}




