@import "../../../styling/vars.scss";

.bg-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, rgba(70, 51, 104, 0.9) 50%, $prm-clr 50%);
    height: 35vh;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(30deg, black, rgba(0, 0, 0, 0.5), transparent, transparent);
    }
    .content-container {
        position: absolute;
        // left: 0;
        width: 100%;
        bottom: 0;
        color: white;
        padding-left: 5%;
    }
    .content {
        color: white;
        backdrop-filter: blur(10px);
        z-index: 90;
    }
}