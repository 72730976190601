@import "../../styling/vars";

a.section-a {
    text-decoration: none;
}
.ourservices {
    .img-bg-container {
        height: 40vh;
        position: relative;
        
        .content-container {
            // justify-content: normal;
            // align-items: end;
            // max-width: 1200px;
            // margin: 0 auto;
    
            position: absolute !important;
            // left: 0;
            bottom: 0;
            color: white !important;
            width: 100%;
            padding-left: 5%;
        }
    }
    .service-list {
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 15px;
        // section.service {
        //     cursor: pointer;
        //     background: white;
        //     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        //     // > .image {
        //     //     position: relative;
        //     //     height: 200px;
        //     //     width: 100%;
        //     //     background: $prm-clr;
        //     //     overflow: hidden;
        //     //     &::after {
        //     //         content: '';
        //     //         position: absolute;
        //     //         inset: 0;
        //     //         background: $prm-clr;
        //     //         top: 100%;
        //     //         transition: top 0.3s;
        //     //     }
        //     //     .content {
        //     //         opacity: 0.5;
        //     //         top: 100%;
        //     //         transition: all 0.5s;
        //     //     }
                
        //     // }
        //     // > .content {
        //     //     padding: 1rem;
        //     //     h2 {
        //     //         color: $prm-clr;
        //     //     }
        //     //     a.active {
        //     //         span {
        //     //             position: relative;
        //     //             z-index: 2;
        //     //         }
        //     //     }
        //     // }

        //     //     &:hover {
        //     //     .image {
        //     //         &::after {
        //     //             top: 0%;
        //     //         }

        //     //         .content {
        //     //             position: absolute;
        //     //             top: 0%;
        //     //             z-index: 10;
        //     //             color: white;
        //     //             padding: 1rem;
        //     //             line-height: 1.6;
        //     //             opacity: 1;
        //     //         }
        //     //     }
        //     // }
        // }


        section.service {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            transition: background-size  350ms ease, box-shadow 350ms ease;
            &:hover {
                box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.5);
                // box-shadow: 0 0px 6px 0 $ter-clr;
            }
            background: linear-gradient(to top left, $prm-clr, $prm-clr, transparent 80%);
            background-size: 300% 400%;
            background-position: 0% 0%;
            > .image {
                height: auto;
                overflow: hidden;
                // border: 1px solid red;
                color: $prm-clr;
                transition: color 1s ease;
                position: relative;
                // &::after {
                //     content: '';
                //     background: $prm-clr;
                //     position: absolute;
                //     inset: 0;
                //     // height: 0%;
                //     top: 100%;
                //     transition: top 350ms ease;
                //     // transform-origin: bottom;
                // }
                img {
                    display: none;
                }
                .content {
                    position: relative;
                    padding: 1rem;
                }
            }
            > .content {
                padding: 1rem;
                h2 {
                    color: $prm-clr;
                }
            }


            &:hover {
                background-size: 500% 500%;
                // background-position: 100% 100%;
                > .image {
                    .content {
                        // color: white;
                        z-index: 1;
                    }
                    &::after {
                        top: 0%;
                        z-index: 0;
                    }
                }
            }
        }


    }
}




.more-btn {
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
    padding: 0.5rem 2rem;
    margin-top: 10px;
    border: 1.5px solid $prm-clr;
    border-radius: 5px;
    color: $prm-clr;
    transition: color 400ms ease;

    position: relative;

    span {
        position: relative;
        font-weight: 400;
        letter-spacing: 1.5px;
        z-index: 2;
    }

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: $prm-clr;
        width: 0%;
        transition: width 350ms ease;
        z-index: 1;
    }

    &:hover {
        color: white;
        &::before {
            width: 100%;
        }
    }
}