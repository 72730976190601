.carousel {
    position: absolute;
    width: 100%;
    height: 100%;
    .slide {
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      }
      
      .slide.active {
        opacity: 1;
      }
}

// .carousel {
//     position: relative;
//   }
  
  