@import "../../../../styling/vars";




.corporate-game {
    .invite-action-container {
        max-width: 700px;
        text-align: center;
        margin: 0 auto;
        padding: 3rem 0;
        // color: $ter-clr;
        font-weight: 700;
        line-height: 1.8;
    }
}