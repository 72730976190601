@import "../../styling/mediaqueries.scss";
@import "../../styling/vars";

@mixin transition {
    transition: all 0.3s ease-in-out;
}


  // input and textfield stylings
  input, textarea {
    width: 100%;
    padding: 0.375rem 0.75rem;
  }
  
  textarea {
    min-height: 150px;
    @include textinput;
  }
  
  input:not(input[type="button"]) {
    // display: none;
    @include textinput;
  }
  
  




.form {
    // line-height: 3rem;
    .section-header {
        margin-top: 2rem;
    }
    
    .field {
        padding: 0.3rem 0;
        line-height: 1.6rem;
    }
    width: 100%;
    max-width: 700px;
    

    .form-btn-div {
        margin: 1rem 0;
        // button {
        //     // @include aw-btn;
        // }
    }

    &.vertical {
        // .text-field {
            label {
                display: block;
            }
        // }
    }

    @include breakpoint($bp: 768px) {
        // &.profileedit-form {
        //     @include col-container;
        //     .segment-body {
        //         @include col-container;
        //     }
        //     .field {
        //         @include col-width;
        //     }
        //     .form-btn-div {
        //         width: 100%;
        //         text-align: right;
        //         button {
        //             @include col-width(1);
        //         }
        //     }
        // }

    }


}
