@import "../../../../../styling/vars";
@import "../../../../../styling/mediaqueries";


.d-content {
    > h2 {
        margin-bottom: 1rem;
        color: $prm-clr;
    }
    .item {
        ul {
            li {
                line-height: 1.5;
                &::before {
                    content: "" !important;
                }
            }
        }

        & + .item {
            margin-top: 1.6rem;
        }
        @include maxBreakpoint($bp: 769px) {
            .how-it-works-list-wrapper {

                > li {
                    // @include rightChevIcon();
                    list-style: none;
                }
            }
        }
        
    }
}