@import "../../styling/mediaqueries";
@import "../../styling/vars";



.contact {
    position: relative;
    background: white;
    img {
        width: 100%;
        max-width: 300px;
    }
    .main-content-bg {
        height: calc(100vh - 50vh - 90px);
        background: rgb(70, 51, 104) !important;
        height: calc(80vh - $header-height);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transform: scaleX(-1);

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: transform 0s ease-in-out;
        .main-content-bg-overlay {
            background-color: rgb(0, 0, 0, 0.4);
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .content-container {
        > .wrapper {
        padding-bottom: 2rem; 
        padding-top: calc(100vh - 90px - 70vh);
        display: flex;
        gap: 2.5rem;
        transition: margin-top 0.3s ease-in-out;
        // new styles 
        @include heightBreakpoint(620px) {
            margin-top: 90px;
        }
        // @include heightBreakpoint(400px) {
        //     // margin-top: 30px;
        //     .faq {
        //         margin-top: 0;
        //         > .top {
        //             height: 100%;
        //         }
        //     }
        // }
        // new styles ends here
        h1 {
            text-transform: uppercase;
            letter-spacing: 2px;
            color: #455568;
        }
        > div {
            width: 50%;
            &:first-child {
                width: 50%;
                /* background: white; */
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // justify-content: end;
                /* align-items: center; */
                /* justify-content: center; */
                line-height: 2rem;

            }
        }
    
        

        .faq {
            color: white;
            h1 {
                color: white;
                line-height: 1.3;
            }
            > .top {
                // New styles starts from here
                min-height: calc(40vh - 90px);
                height: auto;
                display: flex;
                align-items: center;
                // new styles ends here
            }
            // .info-intro-wrapper {
            //     height: 40px;
            //     position: relative;
            //     display: inline-block;
            //     &::before {
            //         content: '';
            //         background: linear-gradient(to right, transparent, $ter-clr, $ter-clr, $ter-clr);
            //         position: absolute;
            //         width: 0%;
            //         height: 100%;
            //         border-top-right-radius: 4px;
            //         border-bottom-right-radius: 4px;
            //         animation: increaseWidth 0.9s forwards 0.6s;
            //     }
            //     &::after {
            //         content: "";
            //         width: 0px;
            //         height: 0px;
            //         position: absolute;
            //         // right: -36px;
            //         opacity: 0;
            //         right: 10px;
            //         top: 2px;
            //         border: 18.5px solid $ter-clr;
            //         border-color: transparent transparent transparent $ter-clr;
            //         z-index: 100;

            //         animation: showArrow 0.3s forwards 1.3s;
            //     }
            //     .info-intro {
            //         font-weight: 700;
            //         padding: 0.2rem;
            //         display: inline-block;
            //         position: relative;
            //         white-space: nowrap;
                    
            //         > span {
            //             color: orange;
            //         }
            //     }
            // }



            .bottom {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                .contact-img-wrapper {
                    img {
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                    }
                }
            }
        }

        .contact-form {
            display: grid;
            place-items: center;
            .form-wrapper {
                width: 90%;
                background: white;
                padding: 1.5rem;
                border-radius: 15px;
                box-shadow: 0 0 5px lightgray;
                h1 {
                    color: $prm-clr;
                }
                h1 + p {
                    color: $prm-clr;
                    font-weight: 500;
                }
            }
        }


        }
    }

    .bottom-info-intro {
        display:  none;
    }

    .sm-scn {
        display: none;
    }
    @include maxBreakpoint {
        .info-intro-wrapper {
            margin-top: 20px;
        }
        .sm-scn {
            display: inline;
        }
        .bg-scn {
            display: none !important;
        }
        .main-content-bg {
            // height: calc(40vh - $header-height);
            height: 50vh;
        }
        .content-container {
            .wrapper {
                flex-direction: column;
                padding-top: 2rem;
                gap: 0rem;
                > div {
                    width: 100%;
                    &:first-child {
                        margin-bottom: 4rem;
                        width: 100%;
                    }
                }
                .faq {
                    margin-top: $header-height;
                    h1 {
                        margin-bottom: 6px;
                    }

                    .info-intro-wrapper{
                        &::before {
                            background: linear-gradient(0deg, $prm-clr 50%,rgba(0, 0, 0, 0.4) 100%, $prm-clr 50%);
                            // background: linear-gradient(to right, transparent, rgb(153, 93, 156), rgb(153, 93, 156), rgb(153, 93, 156));
                        }
                        &::after {
                            display: none;
                        }
                    }

                    .bottom {
                        margin-top: 50px;

                        .service-img-wrapper {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @include maxBreakpoint($bp: 330px) {
        // .info-intro-wrapper {
        //     height: 100%;
        //     .info-intro {
        //         white-space: normal !important;
        //     }
        // }
        .faq .info-intro-wrapper::after {
            top: 6px !important;
        }
        .info-intro {
            display: none !important;
        }

        .bottom {
            color: black;
            margin-top: 0 !important;
            > .bottom-info-intro {
                display:  block !important;
                text-align: center;
                margin-bottom: 2rem;
            }
        }
    }
}



@keyframes increaseWidth {
    0% {
        width: 0%;
        // background: linear-gradient(to right, transparent, $ter-clr, $ter-clr, $ter-clr);
    }
    100% {
        width: 100%;
        // background: linear-gradient(to right, transparent, $ter-clr, $ter-clr, $ter-clr);
    }
}

@keyframes showArrow {
    0% {
        right: 10px;
        opacity: 0;
    }
    100% {
        right: -36px;
        opacity: 1;
    }
}
