@import "../../../../styling/vars";


.demo-content {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.8;
}

