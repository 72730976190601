@import "../../../../styling/mediaqueries";
@import "../../../../styling/vars";


.sec-container {
    img {
        max-width: 300px;
    }
    p {
        line-height: 1.8;
    }

    > .content {
        display: flex;
        > * {
            width: 50%;
            display: grid;
            align-items: center;

            
        }
        grid-template-areas: "image text";

        div:nth-child(1) {
            grid-area: image;
            width: 100%;
            @include breakpoint($bp: 691px) {
                justify-content: flex-start;
                width: auto;
                margin-right: 20px;
            }

            .img-detail {
                color: $prm-clr;
                > p {
                    line-height: 0;
                }
            }
        }

        div:nth-child(2) {
            grid-area: text;
            width: 100%;
        }
    }

    &.right {
        > .content {
            display: grid;
            grid-template-areas: "text image";

            div:nth-child(1) {
                justify-content: center;
            }
        }
    }

    // @include maxBreakpoint($bp: 690px) {

    // }
    @include maxBreakpoint {
        > .content, &.right > .content  {
            display: grid;
            grid-template-areas: "image" "text";
            justify-content: center;
            align-items: center;
            > * {
                width: 100%;
                justify-content: center;
            }
        }

        // &.right {
        //     > .content {
        //         display: grid;
        //         grid-template-areas: "image" "text";
        //         justify-content: center;
        //         // div:nth-child(1) {
        //         //     justify-content: center;
        //         // }
        //     }
        // }


    }
}