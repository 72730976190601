@import "../../styling/vars";

.aw-link {
    text-decoration: none;
    position: relative;
    color: lighten($prm-clr, 10);
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        background: lighten($prm-clr, 20);
        height: 1.4px;
        width: 0%;
        width: 13px;
        transition: width 350ms ease;
    }

    &:hover {
        color: $prm-clr;
        &::after {
            animation: aUnderline 250ms ease forwards;
        }
        
    }



    &.link-arrow {
        @include leftChevIcon();
        &::before {
            opacity: 0;
        }

        &::after {
            width: 0 !important;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }

    > span {
        position: relative;

        &:hover {
            -webkit-text-fill-color: transparent;
            background: $prm-clr;
            -webkit-background-clip: text;
            // color: $prm-clr;
        }
    }
            
}


@keyframes aUnderline {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}


// .aw-link {
//     text-decoration: none;
//     position: relative;
//     color: lighten($prm-clr, 10);
//     &:hover {
//         color: $prm-clr;
//         &::after {
//             content: '';
//             position: absolute;
//             left: 0;
//             bottom: -4px;
//             background: lighten($prm-clr, 20);
//             width: 100%;
//         }
//     }
   
    

//     &.link-arrow {
//         @include leftChevIcon();
//         &::before {
//             opacity: 0;
//         }

//         &:hover {
//             &::before {
//                 opacity: 1;
//             }
//         }
//     }

//     > span {
//         position: relative;

//         &:hover {
//             -webkit-text-fill-color: transparent;
//             background: $prm-clr;
//             -webkit-background-clip: text;
//             // color: $prm-clr;
//         }
//     }
    
// }