// different projects
$accent-clr: #c8f469;
$white: #fff;
$primary-clr: #1E2C38;
$primary-dark: #2b2d42;
$primary-red: #ef233c;
$secondary-red: #d90429;
$bg-clr: #3D5060;
$light-bg-clr: #edf9fb;
$brown-clr: #A42040;
$action-btn-primary-clr: orange;
$action-btn-accent-clr: #FF0201;


$focused-clr: #96c8da;
$link-clr: #00acca;

$success-clr:  #6fd649;
$info-clr: #0dcaf0;
$warning-clr: #ffc107;

$max-width: 1200px;
$header-height: 90px;
$icon-size: 20px;
$icon-clr: #333;

$transition-speed: 0.3s;

$con-padding:  0 2rem;
$base-border-radius: 10px;



@mixin transition($duration: 0.3s) {
    transition: all $duration ease-in-out;
}


// 
$logo-primary-clr: #0f918b;
$logo-red-clr: #e70271;
$logo-yellow-clr: rgb(242, 178, 64);

$prm-clr: rgb(70, 51, 104);
// $prm-clr: rgba(70, 51, 104, 0.764);
$scnd-clr: rgb(131, 148, 222);
$ter-clr: rgb(153, 93, 156);


// $base-border-radius: 10px;
// $focused-clr: #96c8da;


$primary-accent-clr: #a8b533;
$pg-bg-clr: #f5f5f5;

// link styling
$active-link-bg-clr: #de3c39;
$active-link-clr: $pg-bg-clr;

// typography
@mixin container-padding() {
    padding-left: 5%;
    padding-right: 5%;
}

@mixin menu-gap() {
    gap: 16px;
}


@mixin textinput {
    border: 1px solid lighten($prm-clr, 50);
    border-radius: $base-border-radius - 5;
    &:focus-visible {
        outline-color: lighten($prm-clr, 30);
    }
}


// @mixin mask($dir: 45deg, $bgClr: black) {
//     background: linear-gradient(45deg, black, rgba(0, 0, 0, 0.5), transparent, transparent);
// }
@mixin bg-mask($dir: 45deg, $bgClr: black) {
    background: linear-gradient(45deg, black, rgba(0, 0, 0, 0.5), transparent, transparent);
}

@mixin theme-mask($dir: 30deg) {
    background: linear-gradient($dir, $prm-clr, rgba(70, 51, 104, 0.5), transparent, transparent);
}

@mixin bf($icn: '\276E') {
        font-size: 20px;
        line-height: 1em;
        content: $icn;
        position: absolute;
        left: -12px;
        -webkit-transition: all .2s;
        transition: all .2s;
        text-transform: none;
        -webkit-font-feature-settings: "kern" off;
        font-feature-settings: "kern" off;
        font-variant: none;
        font-style: normal;
        text-shadow: none;
        color: $prm-clr;
}

@mixin leftChevIcon() {
    & {
        position: relative;
    }
    &::before {
        @include bf()
    }
}
@mixin rightBF() {
    @include bf($icn: '\276F');
}
@mixin rightChevIcon() {
    & {
        position: relative;
    }
    &::before {
        @include rightBF();
    }
}



@mixin aw-btn($bgClr: $prm-clr) {
        width: 100%;
        padding: 0.75rem 1rem;
        background: $bgClr;
        color: darken(white, 20);
        outline: none;
        border: none;
        cursor: pointer;
        @include transition;
        &:hover {
            background: lighten($bgClr, 10);
            color: white;
        }
        &:disabled,
        &[disabled] {
            background: lighten($bgClr, 20);
            color: darken(white, 10);
            cursor:not-allowed;
        }
}



@mixin aw-accent-btn($bgClr: $ter-clr, $acClr: $prm-clr) {
// @mixin aw-accent-btn($bgClr: $logo-yellow-clr, $acClr: $prm-clr) {
    // background: orange;// linear-gradient();
    // $acc: $logo-red-clr;
    $acc: $bgClr;
    color: white;
    font-weight: 500;
    letter-spacing: 0.5px;
    position: relative;
    padding: 0.03rem;
    border: .5px solid $acc;
    cursor: pointer;
    background: transparent;
    overflow: hidden;
    transition: all 350ms ease, color 100ms ease;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: -50%;
        top: 50%;
        background: linear-gradient(180deg, transparent, transparent, $acClr, $acClr);
        transform-origin: top right;
        animation: rotateAnimation 5s linear infinite;
    }
    span {
        position: relative;
        display: inline-block;
        min-width: 120px;
        max-width: 150px;
        min-height: 35px;
        white-space: nowrap;
        width: 15vmax;
        padding: 0.65rem 1rem;
        background: $acc;
        transition: all 350ms ease;
    }

    &:hover {
        padding: 0;
        box-shadow: 0 0 5px $prm-clr;
        // color: $prm-clr;
        border-radius: 20px;
        span {
            border-radius: 5px;
            padding: 0.68rem 1.03rem;
        }
    }
}




@mixin activeLink($bgClr: $active-link-bg-clr, $fgClr: $active-link-clr) {
    text-decoration: none;
    padding: 6px;
    border-radius: 4px;
    transition: 0.3s;

    &:not(.active):hover {
        color: $bgClr;
        // border-bottom: 1px solid $bgClr;
    }

    &.active {
        // background: $bgClr;
        color: $bgClr !important;
        border-bottom: 1px solid $bgClr;
        &:hover {
            // background: darken($bgClr, 5%);
            color: lighten($bgClr, 10%);
        }
    }
    
}
@mixin aLink($bgClr: $active-link-bg-clr, $fgClr: $active-link-clr) {
    a {
        @include activeLink($bgClr, $fgClr);
    }
}

@mixin menu($bgClr: $active-link-bg-clr, $fgClr: $active-link-clr) {
    display: flex;
    @include aLink($bgClr, $fgClr);
}












@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}