@import "vars";

.gradient-text {
    -webkit-text-fill-color: transparent;
    // background: -webkit-linear-gradient(200deg,#099bf0,#1f58f2,#5618d4);
    // background: -webkit-linear-gradient(200deg,$prm-clr,lighten($prm-clr, 15%), lighten($prm-clr, 30%));
    background: -webkit-linear-gradient(200deg, lighten($prm-clr, 30%),lighten($prm-clr, 15%), $prm-clr);
    -webkit-background-clip: text;
}

button.primary {
    @include aw-btn();
}