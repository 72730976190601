@import "../../../styling/vars";

$size: $icon-size - 4;

.arrow {
    position: relative;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    &.left {
        > div.des {
            padding-left: 20px;
        }
    }

    > span {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        // background: red;
        border: 2px solid;
        border-color:  transparent transparent $icon-clr  $icon-clr;
        transform: rotate(-45deg);
    }
    &.left > span {
        left: -$size / 2;
        transform: translateX(50%) rotate(-45deg);
        border-color:  $icon-clr transparent transparent $icon-clr;
    }
    &.right > span {
        right: $size / 8;
        border-color: transparent $icon-clr $icon-clr transparent;
    }
    &.up > span {
        margin-top: $size / 4;
        border-color:  $icon-clr $icon-clr transparent transparent;
    }

    &.down > span {
        margin-top: -$size / 4;
    }
}