// @import "../../../../../styles/vars";

$select-clr: white;// #2a2f3b; 
$active-clr: rgba(0, 0, 0, 0.03);
$txt-clr: rgba(0,0, 0, 0.87);
$border: 1px solid rgba(34,36, 38, 0.15);
$border-clr:#96c8da;
$padding: 0.75rem 1rem;
$base-border-radius: 15px;
$transition-speed: 0.3s;
$base-padding: 10px;


.dropdown {
    min-width: 15em;
    max-width: 700px;
    position: relative;
    margin: 1rem 0;
    // border-radius: $base-border-radius;
    * {
        box-sizing: border-box;
    }

    .select {
        background: $select-clr;
        // color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: 2px $select-clr solid;
        border: $border;
        color: $txt-clr;
        border-radius: $base-border-radius - 10px;
        padding: $padding;
        cursor: pointer;
        transition: background $transition-speed;
    }

    .select-clicked {
        // border: 2px #26489a solid;
        // box-shadow: 0 0 0.8em #26489a;

        border-radius: $base-border-radius - 5px $base-border-radius - 5px 0 0;
        border-bottom: 0px solid transparent;
        border-color: $border-clr;
        .caret {
            border-radius: 0;
        }
    }

    .select:hover {
        background: $active-clr;
    }

    .caret {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid $txt-clr;
        transition: $transition-speed;
    }

    .caret-rotate {
        transform: rotate(180deg);
    }

    .lists {
        list-style: none;
        padding: $base-padding - 0.15 $base-padding;
        background: $select-clr;
        // border: 1px #363a43 solid;
        // box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
        border: $border;
        box-shadow: 0 2px 3px 0 rgba(34,36, 38, 0.15);
        // border-radius: 0.5em;

        border-radius: $base-border-radius;
        color: black;// #9fa5b5;
        position: absolute;
        top: 3em;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        opacity: 0;
        display: none;
        transition: 0.2s;
        z-index: 1;

        li {
            padding:$padding;
            margin: 0.3rem 0;
            // border-radius: 0.5em;
        border-radius: $base-border-radius;
            cursor: pointer;
            &:hover {
                background: $active-clr;
            }
            &.active {
                background: $active-clr;
            }
        }

    }

    .lists-open {
        display: block;
        opacity: 1;

        border-radius: 0 0 $base-border-radius $base-border-radius;
        top: 38px;
        border-top: 0 solid transparent;
        border-color: $border-clr;
    }

}
