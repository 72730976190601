@import "../../../../styling/vars";

.why-lorcan {
    .content-container {
        // padding: 2rem 0;
        margin: 0 auto;
        text-align: center;
        h1 {
            color: $prm-clr;
        }
        ul li {
            list-style: none;
        }
        .d-content {
            text-align: left;
        }
    }
}