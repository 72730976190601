@import "../../../../styling/mediaqueries";

.landing-service {
    min-height: 40vh;
    background: white;
    // line-height: 3;
    padding: 3rem 0;

    img {
        max-width: 350px;
    }


    .images {
        display: flex;
        > * {
            width: 50%;
            text-align: center;
        }


        @include maxBreakpoint($bp: 520px) {
            flex-direction: column;
            gap: 40px;
            > * {
                width: 100%;
            }
        }

    }
    
    .service-btn {
        text-align: center; 
        padding: 3rem 0 0;
    }
}