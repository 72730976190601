@import "../../../styling/styles";
@import "../../../styling/mediaqueries.scss";


.menu-area {
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    @include menu-gap;
    max-width: 1200px;
    margin: 0 auto;
    @include menu($bgClr: $ter-clr);
    a {
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        margin-right: 3px;
        margin-left: 3px;
    }
    @include maxBreakpoint {
        display: none;
    }
}
