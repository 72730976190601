.breathing {
    > .d-content {
        .item {
            & + .item {
                margin-top: 0;
            }
            
            h3 > span {
                display: block;
                margin-top: 1.2rem;
            }
        }
    }

    .beat-content {
        margin-top: 1.4rem;
    }
}