@import "../../styling/vars";
@import "../../styling/mediaqueries";


article {
    > section {

        line-height: 3;
    }
    section + section {
        // border-top: 20px solid red;
        position: relative;
        padding-top: 20px;
        margin-top: 20px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            height: 0.5px;
            width: 100%;
            background: linear-gradient(to right, transparent, transparent, $prm-clr, $prm-clr, transparent, transparent);
        }
    }

    p + p {
        margin-top: 15px;
    }

   @include maxBreakpoint() {
        .sec-container {
            h1.gradient-text {
                text-align: center;
            }
        }
   }
}


.info-area {
    .mi {
        font-size: 18px;
        font-weight: 700;
    }
}