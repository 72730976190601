@import "../../styling/vars";
@mixin btn {
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    // color: #212529;
    color: white;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    // cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    margin: 0.25rem 0.125rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:not(:disabled) {
        cursor: pointer;
    }
    &:disabled {
        cursor:not-allowed;
    }
}


$blk-txt-clr: #212529;;
.Primary {
    @include btn;
    $p-c: $prm-clr;
    background: $p-c;
    border-color: $p-c;
    position: relative;
    &::after {
        content: '';
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    &:hover {
        background: darken($p-c, 10);
        border-color: darken($p-c, 10);
    }
}

.Secondary {
    @include btn;
    $s-c: lighten($primary-clr, 70);
    background: $s-c;
    border-color: $s-c;
    color: $blk-txt-clr;
    &:hover {
        background: darken($s-c, 10);
        border-color: darken($s-c, 10);
    }
}


.Success {
    @include btn;
    $s-c: #198754;
    background: $s-c;
    border-color: $s-c;
    &:hover {
        background: darken($s-c, 10);
        border-color: darken($s-c, 10);
    }
}

.Danger {
    @include btn;
    $d-c: $primary-red;
    background: $d-c;
    border-color: $d-c;
    &:hover {
        background: darken($d-c, 10);
        border-color: darken($d-c, 10);
    }
}

.Warning {
    @include btn;
    $d-c: $warning-clr;
    background: $d-c;
    border-color: $d-c;
    &:hover {
        background: darken($d-c, 10);
        border-color: darken($d-c, 10);
    }
}
.Accent {
    @include btn;
    $d-c: $accent-clr;
    background: $d-c;
    border-color: $d-c;
    color: $blk-txt-clr;
    &:hover {
        background: darken($d-c, 10);
        border-color: darken($d-c, 10);
    }
}

.GoogleBtn {
    @include btn;
    // $d-c: $accent-clr;
    // background: $d-c;
    // border-color: $d-c;
    // color: $blk-txt-clr;
    // &:hover {
    //     background: darken($d-c, 10);
    //     border-color: darken($d-c, 10);
    // }
    $g-clr: #4285F4;
    width: 100%;
    background: white;
    color: $g-clr;
    border: 1px solid $g-clr;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @include transition;
   
    > svg {
        @include transition;
        font-size: 1.5rem;
        // color: linear-gradient(to bottom,#4285F4 20%, #DB4437 20% 40%, #F4B400 40% 60%, #0F9D58 60% 80%);
        // color: linear-gradient(to bottom,#4285F4, #DB4437, #F4B400, #0F9D58);
        background: linear-gradient(to bottom,#4285F4, #DB4437, #F4B400, #0F9D58);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &:hover {
        $h-clr: darken(#4285F4, 20);
        // background: $h-clr;
        color: $h-clr;
        border: 1px solid $h-clr;
        > svg {
            // color: #4285F4;
        }
    }

}