@import "../../styling/vars";


.landing-pg {
    
    button.accent {
        // @include aw-accent-btn($acClr: green);
        @include aw-accent-btn();
        margin-top: 2vmax;
    }

}

