@import "../../styling/vars.scss";
$logo-green: rgb(1, 91, 38);
$logo-yellow: rgb(176, 126, 38);
$logo-orange: rgb(171, 69, 52);
$logo-red: $logo-red-clr;


header {
    // background: $prm-clr;
    background: white;
    backdrop-filter: blur(2px);
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        // background: linear-gradient(to right, $logo-green, $logo-yellow, $logo-orange, $logo-red);// white;
        background: linear-gradient(to right, $logo-red, $prm-clr, $prm-clr, $prm-clr, $prm-clr);// white;
        // background: $prm-clr;
        width: 100%;
        height: 0.5rem;
    }
}
.header {
    position: absolute;
    width: 100%;
    // background-color: rgba(256, 256, 256, 0.2);
    
    a {
        font-weight: 500;
        // color: #ffffffd9;// white;// #202124;
        color:#555;
        letter-spacing: 0.5px;
    }
    height: $header-height;

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    z-index: 100;
}